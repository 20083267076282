import React from 'react';
import { withPermissionsAndRoles } from '../../session';
import { hasUserRole } from '../../common/util';
import NotificationIcon from './notificationIcon';
import HelpIcon from './helpIcon';
import FeedbackIcon from './feedbackIcon';

function TopMenuOptions({ roles = [] }) {
  return (
    <>
    {hasUserRole(roles) && <>
      <FeedbackIcon/>
      <NotificationIcon/>
      <HelpIcon/>
    </>}
    </>
  );
}

export default withPermissionsAndRoles(TopMenuOptions);

import React, { useState } from 'react';
import { trim, uniqueId } from 'lodash';
import axios from 'axios';

import { SdfAlert, SdfButton, SdfFocusPane, SdfTextarea } from '@synerg/react-components';

export default function ConsultingForm({ isFormOpen, setIsFormOpen }) {
	const [textValue, setTextValue] = useState('');
	const [textValueErrorMsg, setTextValueErrorMsg] = useState('');
	const [textValueValidationState, setTextValueValidationState] = useState(false);
	const [loading, setLoading] = useState(false);
	const [toast, setToast] = useState(false);

	const handleResponse = (message, status = "error") => {
    setToast({
      key: uniqueId(),
      status: status,
      message: message,
      showToast: true
    });
  };

	const closeModal = () => {
		setIsFormOpen(false);
	};

	const validateTextValue = (value) => {
		const valid = !(/[^A-Za-z0-9-,. \n]/.test(trim(value)));

		let errMsg = '';
		if (!valid) {
			errMsg = 'Valid characters permitted are A-Z, a-z, 0-9, period (.), dash (-), comma (,), and spaces.';
		}
		setTextValue(value);
		setTextValueValidationState(!valid);
		setTextValueErrorMsg(errMsg);
	};

	const sendEmail = async () => {
		setLoading(true);
		const body = {
			message: textValue
		};
		await axios.post(`${process.env.REACT_APP_API_PATH}/sendConsultRequestEmail`, body)
			.then(() => handleResponse('Your request has been received. Someone will reach out to you shortly.', 'success'))
			.catch(() => handleResponse('Something went wrong while trying to send consultation request. Please try again.'));
		setLoading(false);
	};

	const ShowError = ({ message }) => {
    return (
      <SdfAlert slot="alert" size="sm" status="error" type="inline">
        {message}
      </SdfAlert>
    );
  };

	return (
		<>
			<SdfFocusPane
				className="text-default"
				paneType="floating"
				visible={isFormOpen}
				closeable={true}
				heading={'Consulting Request'}
				dismissOnClickOutside={true}
				hideDismissButton={true}
				hideAcceptButton={true}
				onSdfDismiss={() => {
					closeModal();
				}}
				spacing="loose"
				size="md"
			>
				{!toast &&
					<>
						<div className="text-heading-03">
							Submit your request for consultation. An ADP consultant can be a valued asset towards
							your API development. Our team will reach out to you shortly.
						</div>
						<SdfTextarea
							className="mb-1"
							rows={6}
							onSdfInput={({ detail }) => { validateTextValue(detail) }} 
							value={textValue}
							maxlength={256}
							state={textValueValidationState ? 'error' : 'normal'}
							resize="none"
							disabled={loading ? true : null}
						>
							{textValueValidationState && textValueErrorMsg && <ShowError message={textValueErrorMsg} />} 
						</SdfTextarea>
						<div className='mt-2 text-right'>{textValue.length}/256</div>
						<div slot="custom-buttons">
							<SdfButton className="mr-3" onClick={() => { closeModal() }} disabled={loading ? true : null}>
								Cancel
							</SdfButton>
							<SdfButton
								emphasis="primary"
								onClick={() => { sendEmail() }}
								disabled={textValue.length === 0 || textValueValidationState || loading ? true : null}
							>
								Send
							</SdfButton>
						</div>
					</>}
				{toast && <div className="text-heading-03 flex justify-between w-full leading-xl">
					<span>{toast.message}</span>
				</div>}
			</SdfFocusPane>
		</>
	);
}

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SdfBusyIndicator } from "@synerg/react-components";
import { SessionContext, SessionProvider } from "./components/session";
import { ApplicationStateProvider } from "./utilities/applicationState";
import OneUxLayout from "./components/oneux-layout";
import Login from "./components/login/login";
import { NotificationProvider } from "./components/shared/notifications";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./App.scss";

export default function App() {
  return (
    <ApplicationStateProvider>
      <SessionProvider>
        <SessionContext.Consumer>
          {(session) => {
            if (session.state === "LOADING") {
              return (
                <div className="app-load">
                  <SdfBusyIndicator
                    style={{ color: "#1a8099" }}
                    size="xl"
                    fullScreen={true}
                  ></SdfBusyIndicator>
                </div>
              );
            } else {
              return (
                <Router basename={`${process.env.REACT_APP_ROOT_PATH}`}>
                  <div id="app" className="app">
                    <Switch>
                      <Route path="/login">
                        <Login />
                      </Route>
                      <Route>
                        <NotificationProvider>
                          <OneUxLayout />
                        </NotificationProvider>
                      </Route>
                    </Switch>
                  </div>
                </Router>
              );
            }
          }}
        </SessionContext.Consumer>
      </SessionProvider>
    </ApplicationStateProvider>
  );
}

import React, { useState, useEffect, useRef } from 'react';

import { SdfButton, SdfCard, SdfAlert, SdfIcon, SfcShellAppBarIcon } from "@synerg/react-components";
import get from "lodash/get";
import axios from "axios";
import { withNotificationContext } from '../../shared/notifications';
import { SHELL_NAV_ICON } from '../../constants';

const NotificationIcon = ({
  showNotificationMenu,
  setShowNotificationMenu
}) => {
  const [expirationToggle, setExpirationToggle] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [hasError, setHasError] = useState(false);

  const menuRef = useRef(null);
  const notificationsIconRef = useRef(null);

  const getSettings = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_PATH}/user/notification-preferences`);

      setNotificationSettings(data.preferences);
      setExpirationToggle(data.preferences.certificateExpiration);

    } catch (e) {
      const errorMessage = get(e, 'response.data.message', 'Something went wrong. Please try again.');
      setHasError(true);
      setErrorMsg(errorMessage);
    }
  }

  useEffect(() => {
    getSettings();
    document.body.addEventListener('click', onClickOutside);

    return () => document.removeEventListener('click', onClickOutside);
  }, []);

  const onClickOutside = e => {
    const element = e.target;
    if (notificationsIconRef.current && notificationsIconRef.current.contains(element)) {
      return;
    }
    if (menuRef.current && !menuRef.current.contains(element)) {
      return setShowNotificationMenu(false);
    }
  };

  const changeSettings = async (key, value) => {
    setHasError(false);

    const newSettings = { [key]: value };
    try {
      await axios.post(`${process.env.REACT_APP_API_PATH}/user/notification-preferences`, newSettings);

      getSettings();
      setShowNotificationMenu(false);

    } catch (e) {
      const errorMessage = get(e, 'response.data.message', 'Something went wrong. Please try again.');
      setHasError(true);
      setErrorMsg(errorMessage);
    }
  };

  return (
    <>
      <SfcShellAppBarIcon
        slot="icons"
        icon="alert-notification"
        ref={notificationsIconRef}
        className={`${SHELL_NAV_ICON} md:mr-4`}
        label="Alerts"
        href="javascript:void(0)"
        onClick={() => {
          setShowNotificationMenu(!showNotificationMenu);
        }}
      />

      <div
        ref={menuRef}
        className={showNotificationMenu ? '' : 'hidden'}
        style={{ position: 'fixed', top: '4rem', right: '8rem' }}
      >
        <SdfCard>
          <div className="pl-inline-md pt-3 pb-4">
            <div className='text-default font-medium font-bold mb-2'>Notification Settings</div>
            <div className='text-default text-sm mb-10'>
              These settings are only for you and will
              <br /> not impact your teammates' settings.
            </div>
            <div>
              <div className='w-64 flex text-default font-medium text-lg font-extrabold mb-4'>
                <div className="w-48">Signed certificate
                  <br />expiration
                </div>
                <div className="pl-inline-lg flex justify-between items-center">
                  <sdf-switch slot="action" checked={expirationToggle} onClick={(ev) => {
                    setExpirationToggle(!expirationToggle);
                  }}>
                    {<SdfIcon
                      icon={expirationToggle ? "action-confirm" : 'action-close'}
                      style={{
                        position: 'absolute',
                        fontSize: '12px', ...(expirationToggle ? { right: '12px' } : { left: '4px' })
                      }}
                    >
                    </SdfIcon>}

                  </sdf-switch>
                </div>
              </div>
              <div className="text-tertiary text-sm mb-10">
                When your signed certificate is within 60
                <br />days of expiration, you will receive
                <br />multiple notifications at regular intervals
                <br />until it has expired.
              </div>
            </div>
            <div className='flex gap-2 ml-24'>
              <SdfButton emphasis="secondary" onClick={() => {
                setExpirationToggle(
                  notificationSettings ?
                    notificationSettings.certificateExpiration : expirationToggle
                )
                setShowNotificationMenu(false);
              }}>
                Cancel
              </SdfButton>
              <SdfButton emphasis="primary" onClick={() => {
                changeSettings('certificateExpiration', expirationToggle);
              }}>
                Save
              </SdfButton>
            </div>
            {hasError && <div className="pt-3">
              <SdfAlert type='inline' status='error'>{errorMsg}</SdfAlert>
            </div>}
          </div>
        </SdfCard>
      </div>
    </>
  );
}
export default withNotificationContext(NotificationIcon);

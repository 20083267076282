import React, { useState, useEffect, useRef } from 'react';
import { SdfAvatar, SdfCard, SdfIcon, SdfLink, SdfMiniCard } from "@synerg/react-components";
import { withSession } from '../../session';
import { appRolesMap } from "../../constants";

function InitialsAvatarIcon({ session = null }) {
  const [fullName, setFullName] = useState('');
  const [initials, setInitials] = useState('');
  const [roles, setRoles] = useState([]);
  const [showingAvatarMenu, setShowingAvatarMenu] = useState(false);

  const menuRef = useRef(null);
  const avatarRef = useRef(null);
  useEffect(() => {
    if (session) {
      const firstInitial = session.given_name.charAt(0).toUpperCase();
      const firstName = firstInitial + session.given_name.slice(1);
      const lastInitial = session.family_name.charAt(0).toUpperCase();
      const lastName = lastInitial + session.family_name.slice(1);

      const items = session.roles.map((role) => appRolesMap[role]);

      setRoles(items);
      setInitials(`${firstInitial}${lastInitial}`);
      setFullName(`${firstName} ${lastName}`);
    }
  }, [session]);

  useEffect(() => {
    document.body.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  }, []);

  const onClickOutside = e => {
    const element = e.target;
    if (avatarRef.current && avatarRef.current.contains(element)) {
      return;
    }
    if (menuRef.current && !menuRef.current.contains(element)) {
      return setShowingAvatarMenu(false);
    }
  };

  return (
    <>
      <div ref={avatarRef} className='flex cursor-pointer'>
        <SdfAvatar class="before" initials={initials} slot="graphic" size="md" shape="circle"
                   onClick={() => setShowingAvatarMenu(!showingAvatarMenu)}/>
      </div>

      <div
        ref={menuRef}
        className={showingAvatarMenu ? '' : 'hidden'}
        style={{ position: 'fixed', top: '4rem', right: '1.5rem' }}
      >
        <SdfCard>
          <SdfMiniCard className="min-w-48 mb-0">
            <SdfAvatar className='self-start' initials={initials} size="lg" slot="graphic"/>
            <div className='text-default text-lg leading-none pt-1' slot="title">
              {fullName}
            </div>
            <div className='text-secondary text-sm' slot="subtitle">
              {roles.map((role, idx) => <div key={idx}>{role}</div>)}
            </div>
          </SdfMiniCard>
          <hr className='-mr-4 -ml-4 mb-2 -mx-6 border-0 border-t-1 border-solid border-dark border-opacity-50'/>
          <SdfLink
            href={`${process.env.REACT_APP_API_PATH}/logout`}
            onClick={() => {
              if (sessionStorage.getItem('showProjectStatus') === 'true') {
                sessionStorage.removeItem('showProjectStatus');
              }
            }}
            action="standard"
            title="signout"
            className='text-default text-lg mt-1'
          >
            <div className="flex">
              <SdfIcon className="mr-2" icon="action-logout"/>
              <div className="text-md">
                <span>Sign out</span>
              </div>
            </div>
          </SdfLink>
        </SdfCard>
      </div>
    </>
  );
}

export default withSession(InitialsAvatarIcon);

import React from 'react';
import { SdfAlert } from '@synerg/react-components';

export default function Toast({ status, message, showToast, autoClose = true, className = '', autoCloseAfter=10000 }) {
	return (
		<>
			<SdfAlert
				status={status}
				closeable="true"
				toast={showToast}
				style={{ display: "block !important" }}
				autoClose={autoClose}
				autoCloseAfter={autoCloseAfter}
			>
				<div className="flex justify-between w-full leading-xl">
					<span className={className}>{message}</span>
				</div>
			</SdfAlert>
		</>
	);
}

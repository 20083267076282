import React from 'react';
import { useHistory } from 'react-router-dom';
import { SfcShellLeftNav } from '@synerg/react-components/dist/sfc-components';
import { withSession } from '../session';
import useMenu from './menu';

function LeftNav({ session = {} }) {
	const history = useHistory();
	const [{ appRoutes }] = useMenu();
	const allRoutes = [...appRoutes];

	const filterRoutes = allRoutes
		.map((route) => {
			if (route.roles.find(role => session.roles.includes(role)) && route.hasOwnProperty("id")) {
				if (route.id === "partners-nav" && session.partners.length < 1) {
					return;
				}
				return route;
			}
		})
		.filter((route) => route !== undefined);

	const activeItem = filterRoutes.find(({ active }) => active);

	return (
		<SfcShellLeftNav
			slot="nav"
			role="navigation"
			menuProvider={filterRoutes}
			activeItem={activeItem}
			onNavigate={(event) => {
				const { detail } = event;
				history.push(detail.pathname);
				event.preventDefault();
			}}
		>
		</SfcShellLeftNav>
	);
}

export default withSession(LeftNav);

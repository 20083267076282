import React from 'react';
import {
	SfcShell
} from '@synerg/react-components/dist/sfc-components';
import {
	SdfIcon,
	SdfButton,
	SdfLink
} from '@synerg/react-components';
import BannerImage from '../../images/login-banner.png';
import { appDirectorHost } from '../../config';

export default function Login() {
	const hasDomainAndProtocol = process.env.REACT_APP_API_PATH.indexOf('https') === 0;
	const authenticateEndpoint = hasDomainAndProtocol
		? `${process.env.REACT_APP_API_PATH}/authenticate`
		: `https://${process.env.REACT_APP_DOMAIN + process.env.REACT_APP_API_PATH}/authenticate`;
	const goToFedSSO = () => {
		window.location.href = `${appDirectorHost[process.env.REACT_APP_ENVIRONMENT]}/setACCookie`;
	}

	return (
		<SfcShell>
			<div className="px-6 text-6xl text-brand-primary">
				<SdfIcon icon={'brand-adp'}></SdfIcon>
			</div>
			<div className="flex mx-24">
				<div className="flex-1 min-h-xs bg-action-default-100 p-24 pr-0">
					<h1 className="text-heading-08 m-0 pb-stack-2xs flex flex-col">
						<span>Welcome, ADP admins to</span>
						<span>API Central</span>
						<div className="w-16 p-1 bg-action-default-500 my-8 inline-block"></div>
					</h1>
					<div className="-mt-8 flex justify-center">
						<img src={BannerImage} alt="Login Banner" />
					</div>
				</div>
				<div className="flex-1 min-h-xs flex flex-col text-center justify-center content-center flex-wrap">
					<h2 className="text-4xl my-2 -mt-20">Log In</h2>
					<h3 className="text-2xl my-2">
						<div>as an admin to</div>
						<div>API Central</div>
					</h3>
					<div className="my-8">
						<SdfButton onClick={goToFedSSO} emphasis={"primary"} className="w-64">
							<span className="text-xl my-3 inline-block">Continue with SSO</span>
						</SdfButton>
					</div>
					<SdfLink href={authenticateEndpoint} action="standard" className="text-lg mt-1 inline-block font-bold -mt-4 text-action-primary">or login with credentials</SdfLink>
				</div>
			</div>
		</SfcShell>
	)
}

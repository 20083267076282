import React from 'react';
import { SfcShellAppBarIcon } from '@synerg/react-components';
import { SHELL_NAV_ICON } from '../../constants';
export default function FeedbackIcon() {


  return (
    <SfcShellAppBarIcon
      slot="icons"
      icon="action-chat"
      className={`${SHELL_NAV_ICON} md:mr-4`}
      label="Feedback"
      href="javascript:void(0)"
      onClick={() => {
      }}
    />
  );
}

export const APP_ROLES = {
  PROJECT_USER: "clientSelfServiceProjectUser",
  CERTIFICATE_USER: "clientSelfServiceCertificateUser",
  MEMBER_USER: "clientSelfServiceMemberUser",
  TEMPLATE_ADMIN: "clientSelfServiceTemplateadmin",
  SUPER_ADMIN: "clientSelfServiceSuperadmin",
  CLIENT_ADMIN: "selfServiceClientadmin",
};

export const appRolesMap = {
  [APP_ROLES.PROJECT_USER]: 'Project user',
  [APP_ROLES.CERTIFICATE_USER]: 'Certificate user',
  [APP_ROLES.MEMBER_USER]: 'Member user',
  [APP_ROLES.TEMPLATE_ADMIN]: 'Template admin',
  [APP_ROLES.SUPER_ADMIN]: 'Super admin',
  [APP_ROLES.CLIENT_ADMIN]: 'Client admin',
}

export const ZENDESK_LINK = 'https://clientsupport.adp.com/hc/en-us/signin?return_to=https%3A%2F%2Fclientsupport.adp.com%2Fhc%2Fen-us%2Frequests%2Fnew&locale=en-us';

export const DEV_PORTAL_LINK = 'https://developers.adp.com';

export const PAGE_SIZES = [{ label: '10', value: 10 }, { label: '20', value: 20 }, { label: '40', value: 40 }, { label: '60', value: 60 }, { label: '80', value: 80 }, { label: '100', value: 100 }];

export const SHELL_NAV_ICON = 'items-center mr-24 cursor-pointer w-10'


//TODO: Look into how to do multiple exports
// This is causing build to not work for dit and iat
// module.exports = {
//   APP_ROLES,
//   appRolesMap
// };

import { useEffect, useState } from 'react';
import { SdfButton, SdfIcon, SdfCloseButton } from '@synerg/react-components';

import { useApplicationState, removeToasts } from "../../utilities/applicationState";
import "./toast.scss";

const Toast = ({ toast }) => {
  const {
    key,
    message, messageClassName, onMessageClose,
    buttonOnClick, buttonText, buttonDisabled, buttonBusy,
    autoCloseAfter, status, icon
  } = toast;
  const [, dispatch] = useApplicationState();
  const [timeoutId, setTimeoutId] = useState(null);

  const onClose = () => {
    if (onMessageClose) onMessageClose();
    dispatch(removeToasts(key))
  };

  useEffect(() => {
    let newTimeoutId = null;
    if (timeoutId) clearTimeout(timeoutId);
    if (autoCloseAfter) {
      newTimeoutId = setTimeout(() => onClose(), autoCloseAfter);
    }
    setTimeoutId(newTimeoutId);
  }, [toast])

  return (
    <div className="toast-list-item w-full flex flex-row gap-4 p-4 bg-white box-border border-solid border-0 border-transparent rounded-xl shadow-xl">
      <div className={`text-xl text-alert-${status || "info"}`}>
        <SdfIcon icon={icon || `alert-${status || "info"}`} className="hydrated" />
      </div>
      <div className="flex-grow">
        <span className={messageClassName}>{message}</span>
      </div>
      { buttonOnClick && 
        <div>
          <SdfButton onClick={buttonOnClick} disabled={buttonDisabled} busy={buttonBusy}>
            {buttonText}
          </SdfButton>
        </div>
      }
      <div>
        <SdfCloseButton size="sm" onClick={onClose} aria-label="Close" tabIndex={0} />
      </div>
    </div>
  );
}

const ToastList = () => {
  const [applicationState] = useApplicationState();

  if (applicationState.toasts.list.length <= 0) return null;

  return (
    <div className="toast-list fixed flex flex-col gap-y-2 items-center">
      {applicationState.toasts.list.map((toast) => (
        <Toast key={`toast-list-item-${toast.key}`} {...{ toast }} />
      ))}
    </div>
  );
}

export default ToastList;

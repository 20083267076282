import React, { createContext, useState } from 'react';

export const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);

  return (
    <NotificationContext.Provider value={{ showNotificationMenu, setShowNotificationMenu }}>
      {children}
    </NotificationContext.Provider>
  );
}

export function withNotificationContext(Component) {
  return function WrapperComponent(props) {
    return (
      <NotificationContext.Consumer>
        {({ showNotificationMenu, setShowNotificationMenu }) => <Component {...props} {...({ showNotificationMenu, setShowNotificationMenu })} />}
      </NotificationContext.Consumer>
    );
  };
}
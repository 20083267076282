import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Toast from '../../common/toast';
import { withSession } from '../../session';
import { SdfFocusPane } from '@synerg/react-components';
import { SdfButton } from '@synerg/react-components';
import { useApplicationState, setExpiresAt } from '../../../utilities/applicationState';

import './sessionIndicator.scss';

function SessionIndicator({ session = {} }) {
  const [ , dispatch ] = useApplicationState();
  const { hasRefreshToken = false } = session;
  const [remainingSessionSeconds, setRemainingSessionSeconds] = useState(
    60 * 60
  );
  const [closedWarning, setClosedWarning] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const remainingSessionMinutes = Math.ceil(remainingSessionSeconds / 60);
  const [logoutTime, setLogoutTime] = useState(
    new Date(Date.now() + 1000 * 60 * 60)
  );
  const [axiosUpdate, setAxiosUpdate] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [showExpiredModal, setExpiredModal] = useState(false);
  const location = useLocation();

  let message = `${remainingSessionMinutes} minutes`;

  if (remainingSessionSeconds < 60) {
    message = 'less than a minute';
  }

  const refreshPage = () => {
    try {
      window.location.reload();
    } catch (e) {}
  };

  const getRemainingSessionSeconds = () => {
    const currentTime = new Date();
    const diffMs = logoutTime - currentTime;
    return diffMs > 0 ? Math.floor(diffMs / 1000) : 0;
  };

  const logout = () => {
    setModalOpen(false);
    sessionStorage.setItem('sessionExpired', 'true');
    if (sessionStorage.getItem('showProjectStatus') === 'true') {
      sessionStorage.removeItem('showProjectStatus');
    }
    window.location.href = `${process.env.REACT_APP_API_PATH}/logout`;
  };

  useEffect(() => {
    const sessionValue = sessionStorage.getItem('sessionExpired');
    if (!sessionValue) {
      sessionStorage.setItem('sessionExpired', 'false');
    } else if (sessionValue === 'true') {
      setExpiredModal(true);
    }
  }, []);

  useEffect(() => {
    if (remainingSessionSeconds === 0) {
      console.log('Logging out...');
      logout();
    }
  }, [remainingSessionSeconds]);

  useEffect(() => {
    if (axiosUpdate) {
      const logout = new Date(Date.now() + 1000 * 60 * 60);
      setLogoutTime(logout);
    }
    setShowAlert(false);
    setClosedWarning(false);
  }, [location, axiosUpdate]);

  const refreshSession = async() => {
    setAxiosUpdate(true);
    updateSession();
  };
  
  const updateSession = async() => {
    try {
			const {status, headers: {'x-session-expires-at': expiresAt}} = await axios.post(`${process.env.REACT_APP_API_PATH}/session-refresh`);
			if(status === 200){
        dispatch(setExpiresAt(expiresAt));
			} else {
        throw new Error("Error refreshing session.");
      }
		} catch(e) {
      throw e;
		}
  }

  useEffect(() => {
    const updateFn = (response) => {
      setAxiosUpdate(true);
      return response;
    };
    

    updateFn.id = 'update';
    axios.interceptors.response.handlers =
      axios.interceptors.response.handlers.filter((fn) => fn.id !== 'update');
    axios.interceptors.response.use(updateFn);

  }, []);

  useEffect(() => {
    const activityTimer = setInterval(() => {
      const currentDifference = getRemainingSessionSeconds();
      setRemainingSessionSeconds(currentDifference);
      if (hasRefreshToken) {
        if (currentDifference > 0 && currentDifference <= 300) {
          setShowAlert(true);
        }
      } else if (!hasRefreshToken) {
        if (currentDifference > 0 && currentDifference <= 300) {
          setClosedWarning(true);
        }
      }
    }, 1000);
    setAxiosUpdate(false);
    return () => {
      clearInterval(activityTimer);
    };
  }, [logoutTime]);

  return (
    <>
      {showAlert && (
        <div>
          <SdfFocusPane
            visible={modalOpen}
            size={'lg'}
            hideAcceptButton={true}
            hideDismissButton={true}
            closeable={false}
          >
            <div slot={'title'}>
              <span>Your session will expire soon</span>
            </div>
            <div
              className={'pt-4 bg-action-default-100'}
              style={{ height: '200px' }}
            >
              <div className={'pl-5'}>
                <span>
                  Your session will expire in {message}. Do one of the
                  following:
                </span>
                <br />
                <ul>
                  <li>
                    Select <b>Stay</b> to reset the expiration and extend your
                    session.
                  </li>
                  <li>
                    Select <b>Leave</b> to sign out.
                  </li>
                </ul>
              </div>
            </div>
            <div slot={'custom-buttons'} style={{ gap: '1rem' }}>
              <SdfButton onClick={logout}>LEAVE</SdfButton>
              <SdfButton
                type='submit'
                emphasis={'primary'}
                onClick={refreshSession}
              >
                STAY
              </SdfButton>
            </div>
          </SdfFocusPane>
        </div>
      )}

      {closedWarning && (
        <div>
          <Toast
            status={'error'}
            message={`For your security, your session will end in ${message}. Please note you will need to log back in once your session has expired.`}
            showToast={true}
            autoClose={false}
          />
        </div>
      )}

      {showExpiredModal && (
        <SdfFocusPane
          visible={true}
          size={'lg'}
          hideAcceptButton={true}
          hideDismissButton={true}
          closeable={false}
        >
          <div slot={'title'}>
            <span>Session Expired</span>
          </div>
          <div
            className={'pt-4 bg-action-default-100'}
            style={{ height: '200px' }}
          >
            <div className={'px-4'}>
              Your session has expired. For security reasons, you have been
              logged out automatically. You must log in again to continue using
              the app. We apologize for the inconvenience.
            </div>
          </div>
          <div slot={'custom-buttons'} style={{ gap: '1rem' }}>
            <SdfButton type='submit' emphasis={'primary'} onClick={refreshPage}>
              SIGN IN
            </SdfButton>
          </div>
        </SdfFocusPane>
      )}
    </>
  );
}

export default withSession(SessionIndicator);
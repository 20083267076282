import React from 'react';
import axios from 'axios';
import {
	SfcShell,
	SfcShellAppBar
} from '@synerg/react-components/dist/sfc-components';
import { SdfPageLayout } from '@synerg/react-components';
import LeftNav from './left-nav';
import BaseRoutes from '../BaseRoutes';
import InitialsAvatarIcon from './topNavBarIcons/initialsAvatarIcon';
import SessionIndicator from '../layout/sideNavBar/sessionIndicator';
import {setExpiresAt, useApplicationState} from '../../utilities/applicationState';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TopMenuOptions from './topNavBarIcons/topMenuOptions';
import ToastList from '../toastList';

const expiresAtArr = [];
const pathKey = 'lastPath';
const baseName = process.env.REACT_APP_ROOT_PATH || "";

// use credentials
axios.defaults.withCredentials = true;
if (!axios.interceptors.response.handlers.length) {
	// use axios interceptor to intercept all responses.
	axios.interceptors.response.use(response => {
		const {headers: {'x-session-expires-at': expiresAt}} = response;
		if(expiresAt){
				// set the expiresAt in session.
				expiresAtArr.unshift(expiresAt)
				sessionStorage.removeItem('sessionExpired');
		}
		return response;
	}, error => {
		console.log('response status: ', error.response.status);
		if (window.location.pathname !== `${process.env.REACT_APP_ROOT_PATH}/login`) {
			if (error.response.status === 401) {
				// store the last location before redirecting to login.
				window.localStorage.setItem(pathKey, window.location.pathname.slice(baseName.length));

				console.info("window.location", window.location.pathname);
				console.error('401 received redirecting to login page..');
				window.location.href = process.env.REACT_APP_API_PATH.indexOf('https') === 0
					? `${process.env.REACT_APP_API_PATH}/authenticate`
					: `https://${process.env.REACT_APP_DOMAIN + process.env.REACT_APP_API_PATH}/authenticate`;
			} else {
				throw(error);
			}
		} else {
			console.log('Ignoring session error on login page.');
		}
	});
}

export default function OneuxLayout() {
	const [ , dispatch] = useApplicationState();
	const history = useHistory();

	useEffect(()=> {
		// restore the previous location if available.
		const lastPath = window.localStorage.getItem(pathKey);
		if (lastPath) {
			if (lastPath !== '/') history.replace(lastPath);
			window.localStorage.removeItem(pathKey);
		}
	}, [])

	useEffect(()=>{
		if(expiresAtArr.length > 0){
			dispatch(setExpiresAt(expiresAtArr[0]));
			expiresAtArr.pop();
		}
	},[])

	return (
		<SfcShell>
			<SfcShellAppBar slot="app-bar" size="default">
				<div className="logo-divider no-branding"></div>
				<div className="branding-area font-semibold">API Central</div>
				<div className="flex flex-row items-end justify-center items-center" slot="icons">
					<TopMenuOptions/>
					<InitialsAvatarIcon />
				</div>
			</SfcShellAppBar>
			<LeftNav />
			<SdfPageLayout>
				<div className="p-0 sm:p-3">
					<ToastList />
					<BaseRoutes />
				</div>
			</SdfPageLayout>
			<SessionIndicator />
		</SfcShell>
	);
}
